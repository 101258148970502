.scroll-bottom {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  color: $color-4;
  font-size: 1.7rem;
  perspective: 40px;

  &:hover .sb-main {
    pointer-events: auto;
  }

  .sb-main {
    display: grid;
    grid-template-columns: 20px 100px 20px;
    grid-template-areas: 'a b c';
    align-items: center;
    justify-items: center;
    width: 150px;
    transition: 0.4s;
    transform: rotateX(25deg) scaleY(0.8);
    transform-origin: bottom;
    pointer-events: none;

    @media screen and (max-width: 430px){
      transform: rotateX(0deg) scaleY(1);
      width: 50px;
    }
    .left,
    .right {
      @media screen and (max-width: 430px){
        transform: scale(2);
      }
    }
    .left {
      grid-area: a;
    }
    p {
      grid-area: b;

      @media screen and (max-width: 430px){
        display: none;
      }
    }
    .right {
      grid-area: c;
    }

    &:hover {
      transform: rotateX(0deg) scaleY(1);
    }
    span {
      font-size: 1.5rem;
      padding: 0 1rem;
      transition: 0.3s;
      display: inline-block;
      transform: translateY(-1px);

      &:hover {
        color: $black;
        font-size: 1.8rem;
      }
    }
  }
}
